/* eslint-disable no-useless-escape */
export const email_valdate = /^[ \w\.\-\+]+\@[\w\.\-\+]+\.[\w\.\-]+$/;

export const password_validate =
	/^(?![0-9_\.\?\@\!\#\$\%\^\&\*]+$)(?![a-zA-Z_\.\?\@\!\#\$\%\^\&\*]+$)[0-9A-Za-z_\.\?\@\!\#\$\%\^\&\*]{6,}$/;

export const company_name_validate = /^\S{1,100}$/;

export const phone_validate = /^\d{6,}$/;

export const phone_email_valdate = /^[A-Za-z0-9]+([-_.][A-Za-z0-9]+)*@([A-Za-z0-9]+[-.])+[A-Za-z]{2,4}$/;

export const cn_mobile = /^[1][3-9]\d{9}$|^([5|6|8|9])\d{7}$|^[0][9]\d{8}$|^[6]([8|6])\d{5}$/;

export const cn_email = /[A-Za-z0-9]+([-_.][A-Za-z0-9]+)*@([A-Za-z0-9]+[-.])+[A-Za-z]{2,4}$/;

export const cn_bank_account_number = /^\d{9,29}$/;

export const cn_taxpayer = /^[0-9A-HJ-NPQRTUWXY]{2}\d{3,6}[0-9A-HJ-NPQRTUWXY]{7,12}$/;

export const cn_phone = /^([0-9]{3,4}-)[0-9]{7,8}$/; // 座机：400-7575888

export const cn_tel_phone = /^\d{2,4}[-]?\d{3,4}[-]?\d{3,4}$/; // 座机：400-7575-888

export const cn_mobile_tel =
	/^((0\d{2,3}(-)?\d{7,8})|(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8})$/; // 座机和手机

export const hk_phone = /^([6|9])\d{7}$|^[0][9]\d{8}$|^6\d{5}$/; // 港澳台手机验证

export const cn_all_phone = /^[1][3-9]\d{9}$|^([6|9])\d{7}$|^[0][9]\d{8}$|^6\d{5}$/; // 中国大陆+港澳台手机验证

export const website_prefix = /^(au|de|de-en|es|fr|it|jp|mx|ru|sg|uk|cn|hk|tw|mo)\//;

export const NUMBER_REGEXP = /^0|[^\d]/g;

export const cn_new_tel = /^1[3-9]\d{9}$/;

// sg手机号验证
export const SG_PHONE_REGEXP = /^[8|9]\d{7}$/;
